import { getConnectUrl } from "@/components/data/connect/utils"
import type { AppData } from "@/services/apps/apps"
import {
  Badge,
  Box,
  Button,
  Group,
  Stack,
  Text,
  rem,
  useMantineColorScheme,
} from "@mantine/core"
import { Link } from "@tanstack/react-router"
import type { InboundEntityExternal } from "@vpnox/sdk"

const BaseConnectionIcon: React.FC<{ index: number }> = ({ index }) => {
  const { colorScheme } = useMantineColorScheme()
  return (
    <Box
      style={theme => ({
        borderRadius: theme.radius.md,
        fontSize: theme.fontSizes.md,
        fontWeight: 700,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: rem(30),
        height: rem(30),
        textAlign: "center",
        color:
          colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.dark[6],
        backgroundColor:
          colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],
      })}
    >
      {index}
    </Box>
  )
}

const BaseConnectionPoint: React.FC<{
  index: number
  children: React.ReactNode
}> = ({ index, children }) => (
  <Group>
    <BaseConnectionIcon index={index} />
    <Text fw={600}>{children}</Text>
  </Group>
)

export const BaseConnectionBlock: React.FC<{
  index: number
  title: string
  children?: React.ReactNode
  description?: string
}> = ({ index, title, description, children }) => (
  <Stack>
    <BaseConnectionPoint index={index}>{title}</BaseConnectionPoint>
    {description && <Text size="sm">{description}</Text>}
    {children}
  </Stack>
)

export type BaseConnectionProps = {
  value: AppData
  inbound: InboundEntityExternal
}

export const BaseConnection: React.FC<BaseConnectionProps> = ({
  value,
  inbound,
}) => {
  const connectUrl = getConnectUrl(
    value.connectUrlMask,
    inbound.subscriptionUrl,
  )

  return (
    <Stack gap="lg">
      <BaseConnectionBlock
        index={1}
        title={"Установите приложение"}
        description={"Нажмите на ссылку ниже, чтобы загрузить программу"}
      >
        <Stack>
          {value.links.map(link => (
            <Button
              key={link.url}
              variant="light"
              fullWidth
              size="sm"
              component={Link}
              to={link.url}
              target="_blank"
            >
              <Group gap="xs">
                {link.name}{" "}
                {!!link.version && (
                  <Badge size="md" radius="sm" variant="outline">
                    {link.version}
                  </Badge>
                )}
              </Group>
            </Button>
          ))}
        </Stack>
      </BaseConnectionBlock>

      <BaseConnectionBlock
        index={2}
        title={"Добавьте конфигурацию"}
        description={
          "Нажмите на ссылку ниже, чтобы добавить конфигурации в программу"
        }
      >
        <Button
          variant="light"
          fullWidth
          size="sm"
          component={Link}
          to={connectUrl}
          target="_blank"
        >
          Добавить в приложение
        </Button>
      </BaseConnectionBlock>

      <BaseConnectionBlock index={3} title={"Настройка завершена"}>
        {/*<Stack>*/}
        {/*  <Button*/}
        {/*    variant="light"*/}
        {/*    fullWidth*/}
        {/*    size="md"*/}
        {/*    component={Link}*/}
        {/*    to={"https://2ip.ru"}*/}
        {/*    target="_blank"*/}
        {/*  >*/}
        {/*    Проверить IP и страну – 2ip.ru*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    variant="light"*/}
        {/*    fullWidth*/}
        {/*    size="md"*/}
        {/*    component={Link}*/}
        {/*    to={"https://speedtest.net"}*/}
        {/*    target="_blank"*/}
        {/*  >*/}
        {/*    Проверить скорость интернета – Speedtest*/}
        {/*  </Button>*/}
        {/*</Stack>*/}
      </BaseConnectionBlock>
    </Stack>
  )
}
