import type { BaseConnectionProps } from "@/components/data/connect/BaseConnection"
import { BaseConnection } from "@/components/data/connect/BaseConnection"
import { InvisibleManApp } from "@/components/data/connect/apps/InvisibleManApp"
import type { App } from "@/services/apps/apps"
import { Accordion, Badge, Group } from "@mantine/core"
import type { InboundEntityExternal } from "@vpnox/sdk"
import { Suspense } from "react"

import classes from "./AppsAccordion.module.css"

type AppsAccordionProps = {
  apps: App[]
  inbound: InboundEntityExternal
}

const getConnectionComponent = (props: BaseConnectionProps) => {
  switch (props.value.name) {
    case "Invisible Man": {
      return <InvisibleManApp {...props} />
    }

    default: {
      return <BaseConnection {...props} />
    }
  }
}

export const AppsAccordion: React.FC<AppsAccordionProps> = ({
  apps,
  inbound,
}) => {
  return (
    <Accordion classNames={classes}>
      {apps?.map(({ icon, ...data }) => (
        <Accordion.Item key={data.name} value={data.name}>
          <Accordion.Control icon={icon}>
            <Group justify="space-between" px={10}>
              {data.name}

              <Group>
                {data.favorite && <Badge size="md">Рекомендуем</Badge>}
              </Group>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Suspense fallback={<>...</>}>
              {getConnectionComponent({
                value: data,
                inbound: inbound,
              })}
            </Suspense>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}
