import { DefaultCatchBoundary } from "@/components/layouts/DefaultCatchBoundary"
import { DefaultLayout } from "@/components/layouts/DefaultLayout"
import { DefaultNotfound } from "@/components/layouts/DefaultNotfound"
import { useMantineColorScheme, useMantineTheme } from "@mantine/core"
import type { QueryClient } from "@tanstack/react-query"
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router"
import {
  type RGB,
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  useMiniApp,
  useSwipeBehavior,
  useThemeParams,
  useViewport,
} from "@telegram-apps/sdk-react"
import React, { useEffect } from "react"

export type RouterContext = {
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  errorComponent: errorProps => (
    <DefaultLayout>
      <DefaultCatchBoundary {...errorProps} />
    </DefaultLayout>
  ),
  notFoundComponent: props => <DefaultNotfound {...props} />,
})

function RootComponent() {
  const miniApp = useMiniApp()
  const themeParams = useThemeParams()
  const viewport = useViewport()
  const theme = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()

  const swipeBehavior = useSwipeBehavior()

  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams)
  }, [miniApp, themeParams])

  // TODO: redirect to settings page
  // const settingsButton = useSettingsButton()
  // useEffect(() => {
  //   settingsButton.show()
  // }, [settingsButton])

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams)
  }, [themeParams])

  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport)
  }, [viewport])

  useEffect(() => {
    if (colorScheme === "dark") {
      if (miniApp.supports("setBackgroundColor")) {
        miniApp.setBgColor(theme.black as RGB)
      }
      if (miniApp.supports("setHeaderColor")) {
        miniApp.setHeaderColor(theme.black as RGB)
      }
    } else {
      if (miniApp.supports("setBackgroundColor")) {
        miniApp.setBgColor(theme.colors.gray[1] as RGB)
      }
      if (miniApp.supports("setHeaderColor")) {
        miniApp.setHeaderColor(theme.colors.gray[1] as RGB)
      }
    }

    if (swipeBehavior.supports("disableVerticalSwipe")) {
      swipeBehavior.disableVerticalSwipe()
    }

    if (viewport && !viewport.isExpanded) {
      viewport.expand()
    }
  }, [viewport, theme, colorScheme, miniApp, swipeBehavior])

  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  )
}
